import {useMemo, useState} from 'react';
import axios from 'axios';
import {useDebouncedCallback} from 'use-debounce';
import {Search, Select} from 'simplekyc-ui-react';
import styled from '@emotion/styled';
// import { Alert } from '@mui/material';
const {fuzzysearch} = require('scored-fuzzysearch');
const MINUTE_MS = 60 * 1000;

interface Props {
    query: string,
    setQuery: (newQuery: string) => void,
    country: string,
    setCountry: (newCountry: string) => void,
    onSelectResult: (selectedResult: Record<string, string>) => void,
    onSelectCountry: () => void
    customerId: number | string,
    appkey: string | null | undefined,
    company: string,
    searchMagicUrl: string,
}

const FormRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 120px;
  grid-template-rows: 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  font-family: "Open Sans";
`;

function SearchInput({
                         query,
                         setQuery,
                         country,
                         setCountry,
                         onSelectResult,
                         onSelectCountry,
                         customerId,
                         appkey,
                         company,
                         searchMagicUrl
                     }: Props) {
    const [slowResults, setSlowResults] = useState<{ records: any[], responseIndex: number }>({
        records: [],
        responseIndex: 0
    });
    const [fastResults, setFastResults] = useState<{ records: any[], responseIndex: number }>({
        records: [],
        responseIndex: 0
    });
    const [requestIndex, setRequestIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(0);
    const [error, setError] = useState<string | null>(null);
    // Access time should be initialised once a token is returned
    const [accessTime, setAccessTime] = useState(new Date());
    const API_URL = process.env.SM_URL || 'https://searchmagic.skyc.cloud';

    // // Test variables for now provided by the client
    // // const [client] = useState(company);
    // const [user] = useState(customerId);
    // // For demo purposes we include a stub value:
    // const appKey = appkey ? appkey : 'c4d3976b-3366-4a26-a2b7-42ff668c27d5';
    // const client = company ? company : "NAB";

    // const searchHistory = useRef([] as string[]);
    // // Save a valid token in the state:
    // const [accessToken, setAccessToken] = useState(localStorage.getItem("token"));
    // // const [tokenExp, setTokenExp] = useState();
    // const saveSearchHistory = (search: string) => {
    //   return searchHistory.current.push(search);
    // }

    // // A useEffect whenever error state changes
    // useEffect(() => {
    //   // This will send an API call whenever error state changes
    //   if (error) {
    //     console.log("Err: " + error)
    //     axios.post(`${API_URL}/usage-log`, {
    //       headers: { "Access-Control-Allow-Origin": "*" },

    //       // Log to the file when component chucks an error
    //       logType: "error",
    //       user,
    //       token: accessToken,
    //       appKey,
    //       client,
    //       accessTime: accessTime,
    //       error,
    //       query,
    //     }
    //     )
    //   };
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [error]);

    // // TO-DO: Later, replace useRef with useState if possible
    // useEffect(() => {

    //   // Authorise the client and user
    //   // Send some fields to the backend
    //   const credentials = {
    //     client,
    //     appKey,
    //     user
    //   }
    //   if (!accessToken) {
    //     console.log("No token found, generating new one ...");

    //     GenerateJwt(credentials, API_URL).then(res => {
    //       if (res.data.error) {
    //         setError(`${res.data.error}`)
    //       } else {
    //         // The JWT is stored in the react state
    //         setAccessToken(res.data.accessToken);
    //         // Set in localStorage for persistence: TEMP SOLUTION
    //         localStorage.setItem("token", res.data.accessToken)
    //         setAccessTime(new Date());
    //         // setTokenExp(res.data.exp);
    //         setTokenExpiry(res.data.exp, res.data.accessToken);
    //         // Below will continously check if jwt is valid
    //         updateUsageLog(res.data.accessToken, new Date());

    //       }
    //     })

    //   } else {
    //     console.log()
    //     // If invalid, start a timer to remove the token from client
    //     checkJwtValid().then(
    //       (res) => {
    //         if (res?.data.isValid) {
    //           // setTokenExp(res.data.exp);
    //           setTokenExpiry(res.data.exp, accessToken);
    //         } else {
    //           localStorage.removeItem("token");
    //           setAccessToken("")
    //         }
    //       });
    //   }


    //   // TO-DO: Implement an unmount
    //   return function cleanup() {
    //     localStorage.removeItem("token");
    //     setAccessToken("")
    //     let endTime = new Date();
    //     let sessionFinish = (endTime.valueOf() - accessTime.valueOf()) / 1000;
    //     updateUsageLog(accessToken, accessTime, sessionFinish);
    //   }
    // }, []);


    // // // Every minute, validate the jwt
    // // useEffect(() => {
    // //   // Make sure we have an accessToken to validate
    // //   if (accessToken) {
    // //     console.log("... Attempting validation ... ")
    // //     const interval = setInterval(() => {
    // //       checkJwtValid().then(res => res?.data.isValid === true ?
    // //         console.log("... Token still Valid! ...") : setAccessToken(""));
    // //       // Now remove it from the state

    // //     }, MINUTE_MS);
    // //     return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    // //   } else {
    // //     localStorage.removeItem("token");
    // //   }
    // //   // eslint-disable-next-line
    // // }, [accessToken])

    // // A parameter varied function which will send any important log info to backend
    // const updateUsageLog = (accessToken: string | null, accessTime: Date, sessionFinish: Number = 0) => {
    //   // Make sure a valid accessToken is passed
    //   if (accessToken) {
    //     axios.post(`${API_URL}/usage-log`, sessionFinish === 0 ? {
    //       headers: { "Access-Control-Allow-Origin": "*" },

    //       // Log to file during component mounting:
    //       logType: "start",
    //       user,
    //       client,
    //       accessToken,
    //       country,
    //       appKey,
    //       accessTime,
    //     } : {
    //       headers: { "Access-Control-Allow-Origin": "*" },

    //       // Log to the file when component unmounts
    //       logType: "end",
    //       accessToken,
    //       user,
    //       client,
    //       appKey,
    //       sessionTimeElapsed: sessionFinish,
    //       searchesMade: searchHistory.current.length,
    //       searchHistory: searchHistory.current,
    //     });
    //   }
    // }

    // const checkJwtValid = async () => {
    //   // Only run this if we have a valid token ,
    //   // How much time has elapsed, needed for rate limiting

    //   // Setup a try and catch block
    //   let res = {
    //     data: {
    //       isValid: false,
    //       exp: Date.now()
    //     }
    //   };

    //   try {
    //     res = await axios.post(`${API_URL}/validate-jwt`, {
    //       headers: { "Access-Control-Allow-Origin": "*" },

    //       searchCount: searchHistory.current.length,
    //       accessToken: accessToken,
    //     });
    //   } catch (e) {
    //     console.log(e);
    //     setError("Error in validating token");
    //   }
    //   return res
    // }

    // const setTokenExpiry = (exp: number, token: string = '') => {
    //   // Set a timeout function for when the token expires:
    //   var highestTimeoutId = setTimeout(function () {
    //     ;
    //   });
    //   for (var i = 0; i < highestTimeoutId; i++) {
    //     clearTimeout(i);
    //   }
    //   const timer = (setTimeout(() => {
    //     console.log("Updating usage log on session finish: ")
    //     let endTime = new Date();
    //     let sessionFinish = (endTime.valueOf() - accessTime.valueOf()) / 1000;

    //     if (token) {
    //       updateUsageLog(token, accessTime, sessionFinish);
    //     }
    //     // TO-DO: Remove the below line when in prod, unless we can handle unmounts
    //     setError("Token has expired, please refresh")

    //     localStorage.removeItem("token");
    //     setAccessToken("");
    //     // Change the below value to be the jwt expiry value
    //   }, exp * 1000 - Date.now() - 1000));
    // }

    /* ------ Search stuff below ------ */

    const debouncedCallback = useDebouncedCallback((query: string) => {

        //if (accessToken) {
        if (query.length < 2) {
            setSlowResults(results => ({...results, records: []}));
            setFastResults(results => ({...results, records: []}));
        } else {
            setRequestIndex(requestIndex + 1);
            setIsLoading(isLoading => isLoading + 2);
            if (digits_only(query))
                query = query.split(' ').join('');
            // Fast
            axios.get(
                `${API_URL}/search-fast/${country}?reduce=1&query=${encodeURIComponent(query)}`,
                {
                    headers: {'Access-Control-Allow-Origin': '*'},
                    timeout: 10000
                }
            ).then(response => {
                setError(null);
                setIsLoading(isLoading => isLoading - 1);
                setFastResults(results => {
                    if (requestIndex + 1 > results.responseIndex) {
                        return {records: response.data.data ?? [], responseIndex: requestIndex + 1}
                    } else {
                        return results;
                    }
                });
                console.log(response)
            }).catch(err => {
                setIsLoading(isLoading => isLoading - 1);
                setError(JSON.stringify(err.message))
            });
            // Slow
            /*if (digits_only(query))
                query = query.split(' ').join('');
            axios.get(
                `${API_URL}/search-slow/${country}?query=${encodeURIComponent(query)}`,
                {
                    headers: {"Access-Control-Allow-Origin": "*"},
                    timeout: 20000
                }
            ).then(response => {
                console.log('res slow',response)
                setError(null);
                setIsLoading(isLoading => isLoading - 1);
                setSlowResults(results => {
                    if (requestIndex + 1 > results.responseIndex) {
                        return {records: response.data.data ?? [], responseIndex: requestIndex + 1}
                    } else {
                        return results;
                    }
                });
            }).catch(err => {
                setIsLoading(isLoading => isLoading - 1);
                setError(JSON.stringify(err.message))
            });*/
        }
        //}
    }, 100);

    function deduplicate<T extends { uniqueIdentifier: string, result: Record<any, any> }>(allResults: T[]) {
        let processedResults = new Map();
        for (let entry of allResults) {
            const identifier = entry.uniqueIdentifier;
            if (processedResults.has(identifier)) {
                const previousRecord = processedResults.get(identifier).result;
                Object.keys(previousRecord).forEach(function (key: string) {
                    previousRecord[key] = previousRecord[key] ?? entry.result[key];
                })
            } else {
                processedResults.set(identifier, entry);
            }
        }

        return Array.from(processedResults.values());
    }

    // Use this to combine, deduplicate result array, and add a match score, and sort by match strength
    const suggestions = useMemo(() => {
        let allResults = fastResults
            .records
            .map(result => {
                result = result.result // add this line, according to the API data structure changing
                return ({
                    result,
                    score: query.toLowerCase().trim() === result.name.toLowerCase().trim() ? 1000 : fuzzysearch(query, result.name),
                    uniqueIdentifier: result.abn
                        || result.acn
                        || result.name
                        || result.id
                })
            });

        const processResultsArray = deduplicate(allResults);
        processResultsArray.sort((a, b) => b.score - a.score);
        return processResultsArray;
    }, [fastResults, query]);

    const digits_only = (string: any) => [...string].every(c => '0123456789 '.includes(c));

    return (
        <>
            {/* {accessToken ? */}
            <>

                {/* <Alert severity="success">
          Access Token Loaded
        </Alert> */}

                <FormRow>
                    <Search
                        label={country === 'AU' ? 'Business Name/ABN/ACN' : 'Name'}
                        value={query}
                        isLoading={!!isLoading}
                        onChange={(value, resultClicked) => {
                            setQuery(value);
                            if (resultClicked) {
                                // saveSearchHistory(text);
                                onSelectResult(resultClicked.result);
                            } else {
                                debouncedCallback(value);
                            }
                        }}
                        results={fastResults
                            .records.map(suggestion => ({
                                title: suggestion.result.name,
                                description: ((
                                    suggestion.result.abn
                                        ? `ABN: ${suggestion.result.abn} `
                                        : ''
                                ) + (
                                    suggestion.result.acn
                                        ? `ACN: ${suggestion.result.acn} `
                                        : ''
                                ) + (
                                    suggestion.result.id
                                        ? `Filing ID: ${suggestion.result.id} `
                                        : ''
                                ) + (
                                    suggestion.result.state
                                        ? `(${suggestion.result.state}) `
                                        : ''
                                )) || undefined,
                                result: suggestion.result
                            }))}

                    />
                    <Select
                        label="Country"
                        initialValue={[{label: country, value: country}]}
                        options={[{label: 'AU', value: 'AU'}, {label: 'US', value: 'US'}]}
                        onBlur={() => {
                        }}
                        onChange={item => {
                            setQuery('');
                            setCountry(item[0].value as string);
                            setSlowResults(results => ({...results, records: []}));
                            setFastResults(results => ({...results, records: []}));
                            onSelectCountry();
                        }}
                    />
                </FormRow>
            </>
            {/* :
        <Alert severity="warning">Access Token Not Granted: {error}</Alert>


      } */}


        </>
    );
}

export default SearchInput;
